import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Feature } from '@shared/features';
import { useFeature } from '@web/common/useFeature';
import { GrowingSpacer, Row } from '@web/components/layout';
import { Button, Input } from 'antd';
import { debounce } from 'lodash';
import * as React from 'react';

import { AdminAddUserModal } from './AdminAddUserModal';
import { AdminAddUsersFromSlackModal } from './AdminAddUsersFromSlackModal';

interface PageExtrasProps {
  searchQuery: string;
  onSearchQueryChanged: (newSearchQuery: string) => void;
  onChange: () => void;
}

export const AdminUsersPageExtras: React.FC<PageExtrasProps> = ({
  searchQuery: initialSearchQuery,
  onSearchQueryChanged,
  onChange,
}) => {
  const { booleanValue: adminUserAddEnabled } = useFeature(
    Feature.ADMIN_USER_ADD,
  );
  const { booleanValue: enableNewUserModel } = useFeature(
    Feature.ENABLE_NEW_USER_MODEL,
  );
  const { booleanValue: slack } = useFeature(Feature.SLACK);

  const [showAdd, setShowAdd] = React.useState(false);
  const [showAddFromSlack, setShowAddFromSlack] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(initialSearchQuery);
  const triggerQueryChange = React.useCallback(
    debounce(onSearchQueryChanged, 400),
    [onSearchQueryChanged],
  );

  const handleSearchQueryChanged = (newSearchQuery: string) => {
    setSearchQuery(newSearchQuery);
    triggerQueryChange(newSearchQuery);
  };

  return (
    <Row gap={6} style={{ justifyContent: 'flex-start' }}>
      <Input
        style={{ width: 300 }}
        placeholder="Search users"
        prefix={<SearchOutlined />}
        value={searchQuery}
        onChange={(e) => handleSearchQueryChanged(e.currentTarget.value)}
        allowClear
      />
      <GrowingSpacer />
      {(adminUserAddEnabled || (enableNewUserModel && !slack)) && (
        <Button
          type="primary"
          onClick={() => {
            setShowAdd(true);
          }}
        >
          <UserAddOutlined /> {enableNewUserModel ? 'Add User' : 'Add Person'}
        </Button>
      )}
      {enableNewUserModel && slack && (
        <Button
          type="primary"
          onClick={() => {
            setShowAddFromSlack(true);
          }}
        >
          <UserAddOutlined /> Add User
        </Button>
      )}
      {showAdd && (
        <AdminAddUserModal
          onCancel={() => {
            setShowAdd(false);
          }}
          onAdd={() => {
            onChange();
            setShowAdd(false);
          }}
        />
      )}
      {showAddFromSlack && (
        <AdminAddUsersFromSlackModal
          onCancel={() => {
            setShowAddFromSlack(false);
          }}
          onAdd={() => {
            onChange();
            setShowAddFromSlack(false);
          }}
        />
      )}
    </Row>
  );
};
